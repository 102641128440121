import React from 'react';
import Page from '../components/Page';
import { graphql } from 'gatsby';
import {
  Layout,
  SplitSection,
  Content,
  Image,
} from '../components/styles/Layout';
import WipeTransition from '../components/WipeTransition';

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "coronado-bridge-1885271_1920.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(
      relativePath: { eq: "david-barajas-RIQyCVb5IQY-unsplash.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(
      relativePath: { eq: "stephen-leonardi-gCN430760r0-unsplash.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(
      relativePath: { eq: "libertycalldistillingproductionfacility.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const AboutUs = ({ data }) => {
  return (
    <Layout style={{ overflow: 'hidden' }}>
      <SplitSection>
        <div className="imageSide">
          <Image
            style={{ height: `calc(100vh - 80px)` }}
            fluid={data['image1'].childImageSharp.fluid}
            alt="Coronado Bridge"
          />
        </div>
        <div className="contentSide">
          <Content className="content">
            <div className="details">
              <hr />
              <h3 className="title">Our Story</h3>
              <p className="cocktailDirections">
                Founders of Liberty Call Distilling, Bill Rogers and Steve
                Grella decided that they wanted to do something a little
                different. They always talked about what they wanted to do
                whilst having a beer at Island Beer Club. There were enough
                micro-breweries in San Diego, they didn’t need to start another
                one. Short of opening up a bar, what could they do that involved
                alcohol and that would be fun and profitable? One of Bill’s
                friends, Mark, hit him up with an idea for making spirits out of
                a small still that they could put in a garage. Bill pointed out
                that not only was that idea highly illegal, it wouldn’t really
                make enough spirits to make the effort and expense worthwhile.
                If Bill was going to distill something, he wanted to make a lot
                of it. That’s when Steve pointed out that he was from Tennessee
                and actually knew people that had made moonshine. Phone calls
                were made, texts were exchanged, Facebook friends were linked
                together, and a plan was hatched.
              </p>
            </div>
          </Content>
        </div>
      </SplitSection>
      <SplitSection>
        <div className="contentSide">
          <Content className="content">
            <div className="details">
              <hr />
              <h3 className="title">The "Research"</h3>
              <p className="cocktailDirections">
                The first step in the plan was to talk to as many distillers as
                they could. Bill and his wife Frances flew to Kentucky for the
                2013 Bourbon Festival in Bardstown. They brought along Mark who
                had come up the idea in first place. Lucky for them, he is a
                process engineer with a background in biochemistry. They toured
                Kentucky and hit up as many distilleries as their blood alcohol
                level would allow. The distillers in Kentucky were fantastic.
                Everyone was very welcoming and answered all of Bill, Fran’s,
                and Mark’s newbie questions with a wink and a grin. Even the
                folks in Kentucky knew that opening a distillery in California
                would be a challenge.
              </p>
            </div>
          </Content>
        </div>
        <div className="imageSide">
          <Image
            style={{ height: `calc(100vh - 80px)` }}
            fluid={data.image2.childImageSharp.fluid}
            alt="Highway in Kentucky"
          />
        </div>
      </SplitSection>
      <SplitSection>
        <div className="imageSide">
          <Image
            style={{ height: `calc(100vh - 80px)` }}
            fluid={data.image3.childImageSharp.fluid}
            alt="California Capital"
          />
        </div>
        <div className="contentSide">
          <Content className="content">
            <div className="details">
              <hr />
              <h3 className="title">The Groundwork</h3>
              <p className="cocktailDirections">
                The second step was to find the answer to “Why was California
                such a hard state to work with in regards to
                micro-distilleries?” Beer and wine laws in the state had come a
                long way in a short time, what about distilleries?
                Unfortunately, spirit producers didn’t have the political clout
                needed to get the laws changed the way beer and wine did.
                Microbreweries and wineries could practice their craft at home
                legally while distillers could not. This has led to more people
                opening up wineries and microbreweries. Unlike wineries and
                breweries, in California a distillery cannot sell directly to
                the public and cannot self-distribute their own products.
                Tasting rooms were not allowed until January 1st, 2014.
                Unbelievably, the liquor laws in Utah are less restrictive than
                in California! It took a leap of faith for distillers to open up
                a distillery.
              </p>
            </div>
          </Content>
        </div>
      </SplitSection>
      <SplitSection>
        <div className="contentSide">
          <Content className="content">
            <div className="details">
              <hr />
              <h3 className="title">The Beginning</h3>
              <p className="cocktailDirections">
                The next step was the leap of faith. Still working full time for
                Snapon Equipment, Bill started by incorporating the business as
                Liberty Call Distilling, LLC. He then started buying equipment,
                and found a place for the distillery to call home in Spring
                Valley. Steve was still active duty in the Navy and had to take
                a hands-off approach until he was officially discharged. The CA
                ABC, and Federal TTB licenses were applied for in March of 2014.
                Then came the waiting game. In the interim, Bill had to outfit
                the distillery, get it plumbed, signed off by Fire Inspectors,
                get County Approvals, and then have background checks run on him
                and his wife. The ABC license came through on September 16th,
                2014. The TTB license came through on October 15th, 2014. This
                was also about the time Steve was finally discharged from the
                Navy, and he was able to take on more responsibilities at the
                distillery. Steve and Bill brought in a 3rd partner, Addison, to
                help with the brewing aspect of the operation. Addison was the
                head brewer at a micro-distillery in Albuquerque, and got a job
                at Acoustic Ales as an assistant brewer after he moved to San
                Diego. The team was finally complete, and they got to work
                brewing and distilling.
              </p>
            </div>
          </Content>
        </div>
        <div className="imageSide">
          <Image
            style={{ height: `calc(100vh - 80px)` }}
            fluid={data.image4.childImageSharp.fluid}
            alt="Liberty Call Distilling Production Facitily"
          />
        </div>
      </SplitSection>
    </Layout>
  );
};

const aboutUs = props => (
  <Page
    transitionStatus={props.transitionStatus}
    transitionDirection="right"
    lightBackground
  >
    <meta name="theme-color" content="#000000" />
    <AboutUs data={props.data} />
    {props.transitionStatus === 'entering' && (
      <WipeTransition animation="finishRightToLeft" />
    )}
  </Page>
);

export default aboutUs;
